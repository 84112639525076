import React from 'react';
import { BannerPanel } from 'cms/items/bannerPanel/view';
import { BannerItem } from 'cms/items/bannerItem/view';
import { Panel } from 'cms/items/panel/view';
import { Html } from 'cms/items/html/view';
import { TestimonialPanel } from 'cms/items/testimonialPanel/view';
import { TestimonialItem } from 'cms/items/testimonialItem/view';
import { ImagePanel } from 'cms/items/imagePanel/view';
//import { PageTiles } from 'cms/items/pageTiles/view';
//import { PageTileAdore } from 'components/pageTileAdore/view';
import { PageTileJ5 } from 'components/pageTileJ5/view';
import { PageTilesBsyc } from 'components/tiles/bsyc/view';
import { SingleBanner } from 'cms/items/singleBanner/view';
import { ContactForm } from 'cms/items/contactForm/view';
import { YoutubeBannerPanel } from 'cms/items/youtubeBannerPanel/view';
import { YoutubeEmbedded } from 'cms/items/youtubeEmbedded/view';
import { Code } from 'cms/items/code/view';
import { BlogPosts } from 'cms/items/blogPosts/view';
import { BlogPostTitle } from 'cms/items/blogPostTitle/view';
import { MultiColumnPanel } from 'cms/items/multiColumnPanel/view';
import { ExpandableHtml } from 'cms/items/expandableHtml/view';
import { PhotoGallery } from 'components/photoGallery/view';
import cmsUtils from '../utils/cmsUtils'

export function Item(props) {
  const item = props.item;
  //const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  //console.log('item', item, subItems, cmsOption);

  let itemElt = (<></>);
  if (item.itemTypeCode === 'Panel') {
    itemElt = (<Panel item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'Html') {
    itemElt = (<Html item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'BannerPanel') {
    itemElt = (<BannerPanel item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'BannerItem') {
    itemElt = (<BannerItem item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'TestimonialPanel') {
    itemElt = (<TestimonialPanel item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'TestimonialItem') {
    itemElt = (<TestimonialItem item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'ImagePanel') {
    itemElt = (<ImagePanel item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'PageTiles') {
    let _tileType = cmsUtils.payload(item, 'TileType');
    const tileType = (_tileType && _tileType !== '') ? _tileType : '2';
    if(tileType === "2"){
      itemElt = (<PageTileJ5 item={item} cmsOption={cmsOption} />);
    }
    else{
      itemElt = (<PageTilesBsyc item={item} cmsOption={cmsOption} />);
    }
  } else if (item.itemTypeCode === 'SingleBanner') {
    itemElt = (<SingleBanner item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'ContactForm') {
    itemElt = (<ContactForm item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'YoutubeBannerPanel') {
    itemElt = (<YoutubeBannerPanel item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'VideoPanel') {
    itemElt = (<YoutubeBannerPanel item={item} cmsOption={cmsOption} />);
  }  else if (item.itemTypeCode === 'YoutubeEmbedded') {
    itemElt = (<YoutubeEmbedded item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'Code') {
    itemElt = (<Code item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'Blog-Posts') {
    itemElt = (<BlogPosts item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'Blog-PostTitle') {
    itemElt = (<BlogPostTitle item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'MultiColumnPanel') {
    itemElt = (<MultiColumnPanel item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'ExpandableHtml') {
    itemElt = (<ExpandableHtml item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'PhotoGallery') {
    itemElt = (<PhotoGallery item={item} cmsOption={cmsOption} />);
  } else {
    itemElt = (<div>{item.itemTypeCode}</div>);
  }
  // const subItemsElts = subItems.map((subItem, index) =>
  //   <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  // );
  return (
    <>
      {itemElt}
      {/* {subItemsElts} */}
    </>
  );
}